import React from 'react';
import Bonus from '../components/bonus';
import Course from '../components/course';

function CourseAndBonus() {
    return (
        <>
            <Course />
            <Bonus />
        </>
    )
}

export default CourseAndBonus;